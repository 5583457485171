import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { Feature, SiteMetadata } from "../components"
import { useModal } from "../context"
import { Layout } from "../layouts/Layout"

const SingleItem = (props) => {
  const { data, location } = props
  const {
    description,
    image: {
      localFiles: [cover],
    },
    name,
    price,
    summary,
  } = data.item.data
  const navigation = location.state ? location.state.navigation : null
  const { modal } = useModal()

  return (
    <Layout navigation={navigation}>
      <SiteMetadata title={name} description={summary} image={cover.url} />
      <article className={modal && "max-h-80vh md:max-h-90vh overflow-auto"}>
        <div className={modal ? "p-4 lg:p-8" : "container py-8"}>
          <h1 className="text-2xl lg:text-3xl text-blue-500 dark:text-blue-400 font-bold leading-tight">
            {name}
          </h1>
          <p className="text-base lg:text-lg text-gray-500 dark:text-gray-400 font-medium mb-4">
            {summary}
          </p>
          <div className="flex flex-wrap">
            <div className="w-full pb-4 lg:w-3/5 lg:pr-4 lg:pb-0">
              <Img
                fluid={cover.childImageSharp.fluid}
                alt={name}
                className="object-cover"
              />
            </div>
            <div className="w-full lg:w-2/5 lg:pl-4">
              <p className="mb-4 whitespace-pre-line text-sm lg:text-base leading-normal text-gray-900 dark:text-gray-600">
                {description}
              </p>
              {price && <Feature label="Price" value={price} />}
              <h4 className="text-blue-800 dark:text-blue-400 uppercase text-xxs tracking-wide font-medium pb-px">
                Ordering Info
              </h4>
              <div className="font-medium text-gray-500 dark:text-gray-400 text-base mb-4">
                Paypal {price} to becca@email.com. In the note, be sure to
                specify your item (“{name}”), along with your name, address, and
                contact info. Thanks!
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default SingleItem

export const query = graphql`
  query SingleItemQuery($slug: String!) {
    item: airtable(data: { slug: { eq: $slug } }) {
      data {
        description
        name
        image {
          localFiles {
            url: publicURL
            childImageSharp {
              fluid(maxWidth: 733, maxHeight: 480, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        price
        slug
        summary
      }
    }
  }
`
